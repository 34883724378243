<template>
  <div class="Loader">
    <v-progress-circular
      indeterminate
      color="green"
      size="70"
      class="loader"
    />
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style scoped lang="scss">
.Loader {
  width: 50vw;
  height: 25vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
