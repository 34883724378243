<template>
  <section class="page">
    <v-breadcrumbs :items="breadcrumbs" />
    <div class="page-title-block">
      <h3>Проекты</h3>
    </div>
    <div class="row mt-10 mb-7" style="margin-right: 0">
      <v-col class="col-sm-5 py-0">
        <v-text-field
          label="Введите название проекта"
          prepend-inner-icon="mdi-magnify"
          filled
          dense
          v-model="handleSearch"
          class="rounded-lg"
          rounded
          hide-details
          height="50"
        ></v-text-field>
      </v-col>

      <div @click="isOpen = true" class="filterButton col-sm-1 py-0">
        <v-icon>mdi-filter</v-icon>Фильтр
      </div>

      <v-dialog :value="isOpen" width="780" @click:outside="isOpen = false">
        <v-card class="modal-container">
          <p class="modal__title">Фильтр</p>
          <v-form ref="form" class="mt-9">
            <div class="row mb-3">
              <div class="col py-0">
                <v-combobox
                  :items="getItemsProjects"
                  label="Название проекта"
                  outlined
                  rounded
                  hide-details
                  v-model="selectedProjects"
                  item-value="id"
                  item-text="value"
                  class="rounded-lg"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-sm-6 py-0">
                <DatePicker
                  class="dateOne col-sm-6 py-0"
                  placeholder="Дата начала проекта"
                  :value="date_start"
                  @changeDate="changeDateFirst"
                  :isRequired="false"
                />
              </div>

              <div class="col-sm-6 py-0">
                <DatePicker
                  class="dateOne col-sm-6 py-0"
                  placeholder="Дата окончание проекта"
                  :value="date_end"
                  @changeDate="changeDateSecond"
                  :isRequired="false"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col py-0">
                <v-combobox
                  :items="getItemsManagers"
                  label="ФИО менеджера"
                  outlined
                  rounded
                  hide-details
                  v-model="selectedManagers"
                  item-value="id"
                  item-text="value"
                  class="rounded-lg"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6 py-0">
                <v-btn
                  color="black"
                  dark
                  class="rounded-lg mt-7"
                  style="width: 100%"
                  x-large
                  outlined
                  @click="reset"
                >
                  <v-icon size="big" color="#AAAAAA" class="mr-1"
                    >mdi-filter-remove</v-icon
                  >
                  <span style="color: #aaaaaa">Сбросить фильтр</span>
                </v-btn>
              </div>

              <div class="col-sm-6 py-0">
                <v-btn
                  color="black"
                  dark
                  style="width: 100%"
                  class="modal__btn mt-7"
                  x-large
                  @click="save"
                >
                  Сохранить
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-card>
      </v-dialog>

      <v-col class="col-sm-3 py-0">
        <v-select
          :items="sortItems"
          v-model="status"
          item-text="text"
          item-value="value"
          label="Показать"
          outlined
          rounded
          class="rounded-lg"
          flat
          solo
          dense
          height="50"
          hide-details
          @change="handleStatusChange"
        ></v-select>
      </v-col>

      <v-tooltip
        left
        v-if="hasRoleAccess(permissionsRoutes.projects.createProject)"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
            v-bind="attrs"
            v-on="on"
          >
            <v-btn
              rounded
              icon
              min-height="49"
              min-width="49"
              fab
              height="49"
              width="49"
              @click="$router.push(`/createProject/`)"
            >
              <v-icon color="#FFFFFF"> mdi-plus </v-icon>
            </v-btn>
          </div>
        </template>
        <span>Добавить проект</span>
      </v-tooltip>
    </div>
    <div class="header">
      <div class="d-flex justify-space-between">
        <v-btn
          text
          icon
          plain
          tile
          :ripple="false"
          :class="sortedClass('title')"
          @click="handleSortBy('title')"
        >
          Название проекта <v-icon rigth>mdi-chevron-down</v-icon>
        </v-btn>
      </div>
      <div class="right d-flex justify-start">
        <v-btn
          text
          icon
          plain
          tile
          :ripple="false"
          class="manager"
          :class="sortedClass('manager_name')"
          @click="handleSortBy('manager_name')"
        >
          Менеджер <v-icon rigth>mdi-chevron-down</v-icon>
        </v-btn>

        <v-btn
          text
          icon
          plain
          tile
          :ripple="false"
          class="date_start"
          :class="sortedClass('date_start')"
          @click="handleSortBy('date_start')"
        >
          Начало <v-icon rigth>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          text
          icon
          plain
          tile
          :ripple="false"
          class="date_end"
          :class="sortedClass('date_end')"
          @click="handleSortBy('date_end')"
        >
          Окончание <v-icon rigth>mdi-chevron-down</v-icon>
        </v-btn>

        <v-btn
          text
          icon
          plain
          tile
          :ripple="false"
          class="budget"
          :class="sortedClass('budget')"
          @click="handleSortBy('budget')"
        >
          Бюджет <v-icon rigth>mdi-chevron-down</v-icon>
        </v-btn>

        <v-btn
          text
          icon
          plain
          tile
          :ripple="false"
          class="progress"
          :class="sortedClass('progress')"
          @click="handleSortBy('progress')"
        >
          Откл-е <v-icon rigth>mdi-chevron-down</v-icon>
        </v-btn>
      </div>
    </div>
    <Loader v-if="loading" />
    <div class="d-flex flex-column gap-12" v-else>
      <ProjectItem
        v-for="el in projects"
        :key="el.id"
        :project="el"
        @removeProject="removeProject"
      />
    </div>

    <NoResultsTable
      v-if="
        !loading &&
        projects.length === 0 &&
        (search ||
          selectedProjects ||
          selectedManagers ||
          date_start ||
          date_end)
      "
      :showLabel="true"
      :labelText="'Ничего не найдено!'"
    />

    <div class="d-flex justify-space-between mt-4">
      <div class="d-flex align-center">
        Показывать по
        <v-select
          v-model="pageSize"
          class="page-size ml-3"
          :items="pageSizes"
          solo
          hide-details
          @change="handlePageSizeChange"
        />
      </div>

      <div v-if="projects.length && !loading && count > projects.length">
        <v-pagination
          v-model="page"
          :length="pages"
          @input="handlePageChange"
          :total-visible="7"
          color="#f1f1f1"
        />
      </div>
    </div>

    <AddProjectModal
      :isProjectAdd="isProjectAdd"
      @changeProjectAdd="changeProjectAdd"
    />

    <DeleteProjectModal
      v-if="isProjectDelete"
      :data="modalData"
      @changeProjectDelete="changeProjectDelete"
      @changeProjectAdd="isProjectDelete = false"
    />
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import ProjectItem from '@/views/projects/components/ProjectItem.vue';
import AddProjectModal from '@/views/projects/components/AddProjectModal.vue';
import DeleteProjectModal from '@/views/projects/components/DeleteProjectModal.vue';
import Loader from '@/components/UI/Loader.vue';
import DatePicker from '@/components/UI/DatePicker.vue';
import NoResultsTable from '@/components/UI/NoResultsTable.vue';

export default {
  data() {
    return {
      date_start: null,
      date_end: null,
      selectedProjects: null,
      selectedManagers: null,
      isOpen: false,
      isProjectAdd: false,
      isProjectDelete: false,
      modalData: null,
      sortItems: [
        {
          text: 'Все проекты',
          value: 'all',
        },
        {
          text: 'Активные',
          value: 'is_active',
        },
        {
          text: 'На стопе',
          value: 'on_hold',
        },
        {
          text: 'Завершенные',
          value: 'completed',
        },
      ],
      sort: {
        key: '',
        isActive: false,
      },
      status: 'all',
      search: '',
      totalPages: 1,
      pageSize: 5,
      pageSizes: [1, 3, 5, 10, 30],
      page: 1,
      count: 0,
      tableParams: {
        project: null,
        date_start: null,
        date_end: null,
        manager: null,
      },
    };
  },
  computed: {
    ...mapState('projects', ['projects']),
    ...mapState({
      filters: 'filters',
      filtersLoading: 'loading',
    }),
    ...mapGetters({
      specialistKindProgress: 'staff/specialistKindProgress',
      managersProgress: 'staff/managersProgress',
      projectsProgress: 'projects/fetchProgress',
    }),
    loading() {
      return (
        this.filtersLoading || this.specialistKindProgress || this.managersProgress || this.projectsProgress
      );
    },

    breadcrumbs() {
      return [
        {
          text: 'Проекты',
          disabled: false,
          exact: true,
          to: { name: 'Projects' },
        },
        {
          text: 'Все проекты',
          disabled: true,
          to: '',
        },
      ];
    },

    pages() {
      if (this.count === 0) {
        return 0;
      }
      return Math.ceil(this.count / this.pageSize);
    },
    handleSearch: {
      get() {
        return this.search;
      },
      set(value) {
        this.search = value;
        this.page = 1;
        this.debounceGetPaginationProjects();
      },
    },

    getItemsProjects() {
      return (
        this.filters?.projects?.map((el) => ({ id: el.id, value: el.value })) || []
      );
    },

    getItemsManagers() {
      return (
        this.filters?.pms?.map((el) => ({ id: el.id, value: el.value })) || []
      );
    },
  },
  methods: {
    ...mapActions('projects', ['getProjects', 'deleteProject']),
    ...mapActions('staff', ['getManagers', 'getSpecialistKind']),
    ...mapActions(['getFilters']),

    changeProjectAdd(val) {
      this.isProjectAdd = val;
    },
    save() {
      this.tableParams.project = this.selectedProjects?.id || null;
      this.tableParams.date_start = this.date_start;
      this.tableParams.date_end = this.date_end;
      this.tableParams.manager = this.selectedManagers?.id || null;
      this.getPaginationProjects();
      this.isOpen = false;
    },
    reset() {
      this.selectedProjects = null;
      this.date_start = null;
      this.date_end = null;
      this.selectedManagers = null;
      this.save();
    },
    changeDateFirst(val) {
      this.date_start = val;
    },

    changeDateSecond(val) {
      this.date_end = val;
    },

    debounceGetPaginationProjects: debounce(function deb() {
      this.getPaginationProjects();
    }, 300),

    handleSortBy(key) {
      this.sort.isActive = this.sort.key === key ? !this.sort.isActive : false;
      this.sort.key = key;
      this.getPaginationProjects();
    },

    sortedClass(key) {
      return this.sort.key === key ? `${this.sort.isActive ? 'active' : ''} ${this.sort.key === key ? 'activeSort' : ''}` : '';
    },

    removeProject(id) {
      this.modalData = id;
      this.isProjectDelete = true;
    },

    async changeProjectDelete(id) {
      await this.deleteProject(id);
      this.isProjectDelete = false;
      this.getPaginationProjects();
    },
    handlePageChange() {
      this.getPaginationProjects();
    },

    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.getPaginationProjects();
    },

    handleStatusChange() {
      this.getPaginationProjects();
    },
    async getPaginationProjects() {
      await this.getProjects({
        offset: (this.page - 1) * this.pageSize,
        limit: this.pageSize,
        status: this.status === 'all' ? null : this.status,
        ordering: this.sort.key
          ? this.sort.isActive
            ? `${this.sort.key}`
            : `-${this.sort.key}`
          : null,
        search: this.search || null,
        project: this.tableParams.project || null,
        date_start: this.tableParams.date_start || null,
        date_end: this.tableParams.date_end || null,
        manager: this.tableParams.manager || null,
      })
        .then(({ count, next, previous }) => {
          this.totalPages = count;
          this.count = count;
          this.next = next;
          this.previous = previous;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  created() {
    this.getFilters();
    this.getSpecialistKind();
    this.getManagers();
    this.getPaginationProjects();
  },

  components: {
    DatePicker,
    ProjectItem,
    AddProjectModal,
    Loader,
    DeleteProjectModal,
    NoResultsTable,
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/styles/variables.scss';

.v-btn:before {
  background-color: #2d2d2d !important;
  opacity: 1 !important;
}

.v-btn.active .v-icon {
  transform: rotate(-180deg);
}

::v-deep .v-btn--icon {
  width: unset;
  height: unset;
}

::v-deep .v-text-field--outlined fieldset {
  border: 1px solid #d6d6d6 !important;
  box-sizing: border-box;
}

::v-deep .v-pagination__item {
  background: #f8f8f8 !important;
  border-radius: 8px !important;
  color: #aaaaaa !important;
  box-shadow: none !important;

  &--active {
    color: #212121 !important;
    background: #f1f1f1 !important;
  }
}

::v-deep .v-pagination__navigation {
  box-shadow: none !important;
}

.page-size {
  width: min-content !important;
  ::v-deep .v-input__control {
    height: 29px !important;
    min-height: 29px !important;
    background: #f1f1f1 !important;
  }

  ::v-deep .v-input__slot {
    background: #f1f1f1 !important;
    border-radius: $border-radius-root;
  }

  ::v-deep fieldset {
    background: #f1f1f1 !important;
  }
}

.header {
  width: 100%;
  margin-bottom: 15px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;

  .right {
    padding-right: 60px;

    .manager {
      width: 150px;
    }
    .date_start {
      width: 110px;
    }
    .date_end {
      width: 110px;
    }
    .budget {
      width: 110px;
    }
    .progress {
      width: 110px;
    }
    button {
      text-align: center;
    }
  }
}

.filterButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  margin-right: auto;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
::v-deep .activeSort {
  color: black !important;
  .v-btn__content {
    opacity: 1 !important;
  }
}
</style>
