<template>
  <div class="noresults-table" v-if="(results && results.length == 0 && listCount > 0) || showLabel">
    <div class="noresults-table__img"></div>
    <p v-if="searchText">По вашему запросу «{{ searchText }}» ничего не найдено</p>
    <p v-else>{{ labelText ? labelText : 'Ничего не найдено' }}</p>
  </div>
</template>

<script>
export default {
  name: 'NoResultsTable',
  props: {
    showLabel: {
      type: Boolean,
      default: false,
    },
    results: {
      default: null,
    },
    listCount: {
      type: Number,
      default: 0,
    },
    searchText: {
      default: false,
    },
    labelText: {
      default: null,
    },
  },
};
</script>
