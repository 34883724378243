<template>
  <div class="project__card" @click="$router.push(`/projects/${project.id}`)">
    <div class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <div class="project__image mr-3">
          <img v-if="project.image_url" :src="project.image_url">
          <span v-else>{{project.title.substr(0, 1)}}</span>
        </div>
        <div>
          <h3 class="project__title">{{ project.title }}</h3>
          <div class="d-flex align-center text-light-gray fw-500">
            <div :class="['status-icon status-icon_circle mr-2',
            {'status-icon_success': project.status === PROJECT_STATUSES.IS_ACTIVE}]" />
            {{statusText}}
          </div>
        </div>
      </div>
    </div>

    <div class="project-info">
      <div class="d-flex align-center min-w-300px">
        <div v-if="manager" class="avatar mr-3">
          <div class="avatar__status active" />
          <img v-if="manager.avatar" :src="manager.avatar">
          <span v-else>{{manager.value.substr(0, 1)}}</span>
        </div>
        <div class="project-info__item">
          <div class="project-info__title">Менеджер</div>
          <div v-if="manager">{{ manager.value }}</div>
        </div>
      </div>
      <div class="d-flex gap-16 justify-space-between w-100">
        <div class="vertical-separator" />
        <div>
          <div class="project-info__title">Начало проекта</div>
          <div>{{project.date_start}}</div>
        </div>
        <div>
          <div class="project-info__title">Конец проекта</div>
          <div>{{project.date_end}}</div>
        </div>
        <div>
          <div class="project-info__title">План бюджет</div>
          <div>{{project.budget.toLocaleString()}} ₽</div>
        </div>
        <div>
          <div class="project-info__title">Факт. бюджет</div>
          <div>{{project.budget_real.toLocaleString()}} ₽</div>
        </div>
        <div>
          <div class="project-info__title">Прогресс</div>
          <div class="d-flex align-center">
            <v-progress-circular :value="project.progress"
                                 :color="project.progress>100?'error':'success'"
                                 size="18" class="mr-1"/>
            <span :class="project.progress>100?'text-error':'text-success'">{{project.progress}}%</span>
          </div>
        </div>
        <div>
          <div class="project-info__title text-error">Отклонение</div>
          <div :class="{'text-error': deviation}"><template v-if="deviation">+</template>{{ deviation }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { PROJECT_STATUSES } from '@/core/constants/projectStatuses';

export default {
  data: () => ({
    PROJECT_STATUSES,
  }),
  props: {
    project: {
      type: Object,
    },
  },
  computed: {
    ...mapState(['filters']),
    statusText() {
      switch (this.project.status) {
        case PROJECT_STATUSES.IS_ACTIVE: return 'Активный';
        case PROJECT_STATUSES.ON_HOLD: return 'На стопе';
        case PROJECT_STATUSES.COMPLETED: return 'Завершённый';
        default: return '';
      }
    },
    manager() {
      return this.filters?.pms.find((pm) => pm.id === this.project.manager);
    },
    deviation() {
      const deviation = this.project.progress - 100;
      return deviation > 0 ? deviation : 0;
    },
  },
};
</script>
