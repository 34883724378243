<template>
  <v-dialog
    :value="isProjectAdd"
    width="1050"
    @click:outside="$emit('changeProjectAdd', false)"
  >
    <v-card class="modal-container">
      <p class="modal__title">Добавить проект</p>

      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="mt-8"
      >
        <div class="d-flex justify-space-between mb-10">
          <div class="col-item">
            <v-text-field
              v-model="project.title"
              :rules="nameRules"
              label="Введите название"
              class="rounded-lg"
              required
              filled
              rounded
            ></v-text-field>

            <v-text-field
              v-model="project.link"
              label="Ссылка откуда выгрузить данные"
              class="rounded-lg"
              required
              filled
              rounded
            ></v-text-field>
          </div>

          <div class="col-item d-flex justify-space-between">
            <div class="col-item">
              <v-text-field
                v-model="project.budget"
                label="Бюджет проекта"
                class="rounded-lg"
                required
                filled
                rounded
              ></v-text-field>

              <v-text-field
                v-model="project.date_start"
                label="Дата начала"
                class="rounded-lg"
                required
                filled
                rounded
              ></v-text-field>
            </div>

            <div class="col-item">
              <v-select
                :items="pms"
                v-model="project.position"
                label="РМ"
                class="rounded-lg"
                item-value="id"
                item-text="name"
                required
                filled
                rounded
              ></v-select>

              <v-text-field
                v-model="project.date_end"
                label="Дата окончания"
                class="rounded-lg"
                required
                filled
                rounded
              ></v-text-field>
            </div>
          </div>
        </div>

        <p class="modal__sub-title">Стоимость специалиста</p>

        <div class="staff-list">
          <div
            class="staff-list__item"
            v-for="el in rates"
            :key="el.id"
          >
            <v-text-field
              v-model="project.position"
              :label="el.title"
              class="rounded-lg mr-3"
              filled
              rounded
              hide-details
              disabled
            ></v-text-field>

            <v-text-field
              v-model="project.date_end"
              :label="el.rate"
              class="rounded-lg mr-4"
              filled
              rounded
              hide-details
              disabled
            ></v-text-field>

            <v-btn
              icon
              color="red"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>

      </v-form>

      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="primary"
          class="mt-10"
          x-large
          @click="$emit('changeProjectAdd', false)"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddProjectModal',

  data() {
    return {
      valid: true,
      isPassword: false,
      project: {
        title: '',
        link: '',
        budget: '',
        dateStart: '',
        dateFinish: '',
      },
      rates: [
        { id: 1, title: 'Designer', rate: '2000' },
        { id: 2, title: 'iOS', rate: '2000' },
        { id: 3, title: 'QA', rate: '2000' },
        { id: 4, title: 'Back', rate: '2000' },
        { id: 5, title: 'Analist', rate: '2000' },
        { id: 6, title: 'Front', rate: '2000' },
        { id: 7, title: 'Android', rate: '2000' },
        { id: 8, title: 'DevOps', rate: '2000' },
      ],
      pms: [
        { id: 1, name: 'Ратошкина Елена Алексеевна' },
        { id: 2, name: 'Давыдов Олег Алексеевич' },
      ],
    };
  },

  props: {
    isProjectAdd: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.col-item {
  width: 49%;
}

.modal__sub-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
}

.staff-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.staff-list__item {
  width: 49%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  & .v-input {
    max-width: 190px;
  }
}
</style>
